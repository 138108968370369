import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'members', label: "Members", sortable: false },
    { key: 'status', label: "Status", sortable: false },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const groups = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }


  const fetchCategories = (ctx, callback) => {
    store
      .dispatch('client-groups/fetchGroups', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data } = response.data
        groups.value = data
        totalUsers.value = data.length;

      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  fetchCategories()
  const resolveUserStatusVariant = status => {
    if (status === null) return 'primary'
    return 'success'

  }
  const resolveUserStatusVariantText = status => {
    if (status === null) return 'primary'

    return status;
  }

  const resolveUserRolesArray = roles => {
    //console.log("Roles => ",roles);
    var rolesArray = [];
    if (roles.length > 0) {
      for (var i = 0; i < roles.length; i++) {
        rolesArray.push(roles[i].description);
      }
    }
    return rolesArray;
  }


  return {
    fetchCategories,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    groups,

    resolveUserStatusVariant,
    resolveUserStatusVariantText,
    refetchData,
    resolveUserRolesArray,
    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
